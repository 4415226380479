import React from 'react'

const ValidationCampaingActions = (campaign) => {

    const scheduled = campaign.channels?.some((c) => {
        return c.status === "scheduled"
    })

    const sended = campaign.channels?.some((c) => {
        return c.status === "sended"
    })

    const inProgress = campaign.channels?.some((c) => {
        return c.status === "inProgress"
    })

    const created = campaign.channels?.every((c) => {
        return c.status === "created"
    })

    if (campaign?.deactivatedAt) return 'finishCampaing'


    if (inProgress || (scheduled && sended)) return 'duringCampaing'


    if (campaign.types[0] === "offer" && campaign.dateTo) {

        let currentDate = new Date();
        currentDate.setMinutes(currentDate.getMinutes());
        const startDateCampaign = new Date(campaign.dateFrom)
        const closingDateCampaign = new Date(campaign.dateTo)
        if (currentDate > closingDateCampaign) return 'finishCampaing'
        if (currentDate < closingDateCampaign && currentDate > startDateCampaign) return 'duringCampaing'
    }

    if (created) return 'beforeCampaing'

    if (sended) return 'finishCampaing'

    if (scheduled) return 'beforeCampaing'
}

export default ValidationCampaingActions
