import React from 'react';
import ProgressBar from './ProgressBar';


const CampaignStatusCheck = ({ campaign }) => {

  const scheduled = campaign.channels?.some((c) => {
    return c.status === "scheduled"
  })

  const sended = campaign.channels?.some((c) => {
    return c.status === "sended"
  })

  const inProgress = campaign.channels?.some((c) => {
    return c.status === "inProgress"
  })

  const created = campaign.channels?.every((c) => {
    return c.status === "created"
  })

  if (campaign?.deactivatedAt) {
    return (
      <>
        <div>Cancelada</div>
      </>
    )
  }

  if (inProgress || (scheduled && sended)) {

    let soma = campaign.channels.reduce(function (total, channel) {
      return total + parseFloat(channel.percentage);
    }, 0);
    let media = soma / campaign.channels.length;
    media = Math.floor(media)
    return (
      <>
        <ProgressBar value={media} text={"Enviando"} />
        <span>Enviando</span>
      </>
    )
  }


  if (campaign.types[0] === "offer" && campaign.dateTo) {
    let currentDate = new Date();
    currentDate.setMinutes(currentDate.getMinutes());
    const startDateCampaign = new Date(campaign.dateFrom)
    const closingDateCampaign = new Date(campaign.dateTo)
    if (currentDate > closingDateCampaign) {
      return (
        <>
          <div>Encerrada</div>
        </>
      )
    }
    if (currentDate < closingDateCampaign && currentDate > startDateCampaign) {
      return (
        <>
          <div>Vigente</div>
        </>
      )
    }
  }

  if (created) {
    return (
      <div>Agendada</div>
    )
  }


  if (sended) {
    return (
      <>
        <div>Enviada</div>
      </>
    )
  }

  if (scheduled) {
    return (
      <>
        <div>Agendada</div>
      </>
    )
  }

};

export default CampaignStatusCheck;
