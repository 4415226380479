import React from 'react'
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap'

export const ActionsCell = ({ cell }) => {
  const { cellsTableData, index, item, action } = cell
  const actionItem = cellsTableData.find((data) => data.property === 'actions')

  return (
    <>
      <td height={70} key={index} width={50} onClick={() => action ? action(item) : null}>
        <Dropdown isOpen={item.dropdownOpen} toggle={() => actionItem.toggle(item)} direction='left'>
          <DropdownToggle tag="a" className='px-4'>
            <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
          </DropdownToggle>
          <DropdownMenu>
            {actionItem.items && actionItem.items.map((menuItem, i) => menuItem.show(item) && <DropdownItem key={i} onClick={() => menuItem.action(item)}>{menuItem.label}</DropdownItem>)}
            {actionItem.component && actionItem.component(item)}
          </DropdownMenu>
        </Dropdown>
      </td >
    </>
  )
}
