import React, { useState, useEffect, useRef, useContext } from "react";
import { useDispatch } from "react-redux"
import * as moment from 'moment'
import Calendar from "react-calendar";
import { statusAttendance, getChannels, getDepartments, getFinishReasons, attendanceTypeStatus } from "../Services/Attendance-Service";
import { getUser } from "../Services/NewUser-Service";
import { getCustomersManager } from "../Services/User-Service";
import { useMobile } from "./Partials/useMobile/useMobile";
import { Table, Label, Tooltip } from 'reactstrap';
import Select from 'react-select';
import CardsLayoutServiceReports from "./Partials/CardsLayout/CardsLayoutServiceReports";
import { TableBody } from "./Partials/Table/TableBody";
import { TableHeader } from "./Partials/Table/TableHeader";
import useFormattedPhone from "../Utils/phone-formatter";
import PerfectScrollbar from 'react-perfect-scrollbar'
import MultiSelect from "./Partials/MultiSelect";
import { sidebarAction } from "../Store/Actions/sidebarAction";
import { AttendanceContext } from "../Context/AttendanceContext";
import { useAuth } from "../Context/AuthContext";
import { useHistory } from "react-router-dom";
import Chat from "./Partials/Chat/Chat";
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import DetailsModal from "./Modals/DetailsModal";
import DefaultImage from '../assets/img/no-image.png'

const LayoutServiceReports = () => {

  const [showCalendar, setShowCalendar] = useState(null)
  const [calendarForFilter, setCalendarForFilter] = useState('')

  const [dataList, setDataList] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [showSearchFields, setShowSearchFields] = useState(false);
  const [maxPages, setMaxPages] = useState(0);
  const [page, setPage] = useState(1);
  const [operatorsOptions, setOperatorsOptions] = useState([])
  const [choosedOperators, setChoosedOperators] = useState([])
  const [statusService, setSatusService] = useState([])
  const [channels, setChannels] = useState([])
  const [choosedChannel, setChoosedChannel] = useState([])
  const [chosenChannels, setChosenChannels] = useState([])
  const [department, setDepartment] = useState([])
  const [choosedDepartment, setChoosedDepartment] = useState([])
  const [finishReasons, setFinishReasons] = useState([])
  const [choosedReasons, setChoosedReasons] = useState([])
  const [dateFrom, setDateFrom] = useState(moment().format('YYYY-MM-DDT00:00:00'));
  const [dateTo, setDateTo] = useState(moment().format('YYYY-MM-DDT23:59:59'));
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [maxContacts, setMaxContacts] = useState(0);
  const [optionsContact, setoptionsContact] = useState([])
  const [selectedContact, setSelectedContact] = useState(null);
  const [filter, setFilter] = useState({});
  const [openModalDetails, setOpenModalDetails] = useState('')
  const [situationOptions, setSituationOptions] = useState([])

  const {
    setActiveChatCustomer,
    setChatReadOnly,
    setShowMessageHistoryModal,
    setMessagesForServiceReports
  } = useContext(AttendanceContext)
  const tooltipToggle = () => setTooltipOpen(!tooltipOpen);
  const isMobile = useMobile();
  const scrollRef = useRef(null)
  const typeaheadRef = useRef(null)
  const decodedToken = useAuth()
  const dispatch = useDispatch()
  const history = useHistory()
  const filterBy = () => true;

  const toggleSearchFields = () => {
    setShowSearchFields((prev) => !prev);
  };

  const handleGetChannels = async () => {
    const res = await getChannels()
    setChannels(res.map(channel => {
      return {
        value: channel._id,
        label: channel.shortName || channel.name,
      }
    }))
  }

  const handleGetDepartaments = async () => {
    const res = await getDepartments()
    setDepartment(res.map(department => {
      return {
        value: department._id,
        label: department.name
      }
    }))
  }

  const handleGetFinishReasons = async () => {

    const res = await getFinishReasons()
    setFinishReasons(res.map(reason => {
      return {
        value: reason._id,
        label: reason.name
      }
    }))
  }

  const handleSearch = async () => {
    setLoading(true);

    const arrayChannels = chosenChannels?.map(channel => channel?.value)
    const arrayDepartments = choosedDepartment?.map(channel => channel?.value)
    const arrayReason = choosedReasons?.map(reason => reason?.value)

    const filtersObjt = {
      dateFrom: dateFrom,
      dateTo: dateTo,
      status: statusService.value,
      channelIds: arrayChannels.length ? arrayChannels : null,
      operatorId: choosedOperators.value,
      departmentIds: arrayDepartments.length ? arrayDepartments : null,
      reasonTypeIds: arrayReason.length ? arrayReason : null,
      customerId: selectedContact ? selectedContact._id : null

    }

    const filtersWithPage = { ...filtersObjt, order: 'desc' }
    try {
      if (scrollRef.current && scrollRef.current.scrollTop) {
        scrollRef.current.scrollTop = 0
      }
      const response = await statusAttendance(filtersWithPage, 1)
      setDataList(response.data)
      setPage(1)
      setMaxPages(response.meta.pages)
      setMaxContacts(response.meta.rows);
      setFilter(filtersObjt)
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  const handleGetCustomers = async (newDate, pageAfterEdit) => {

    const currentDate = moment(newDate);
    const dateFrom = currentDate.startOf('day').format('YYYY-MM-DDT00:00:00');
    const dateTo = currentDate.endOf('day').format('YYYY-MM-DDT23:59:59');
    const filters = {
      dateFrom: dateFrom,
      dateTo: dateTo
    }

    const filtersWithPage = { ...filters, order: 'desc' }
    setFilter(filtersWithPage)
    try {
      setLoading(true);
      const response = await statusAttendance(filtersWithPage, pageAfterEdit ? pageAfterEdit : page)
      setDataList(response.data)
      setMaxPages(response.meta.pages);
      setMaxContacts(response.meta.rows);

      if (pageAfterEdit) {
        setPage(pageAfterEdit);
        setFilter({})
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  const handleGetAttendanceTypeStatus = async () => {
    const response = await attendanceTypeStatus()
    setSituationOptions(
      response.map(status => {
        return {
          label: status.description,
          value: status.name
        }
      })
    )
  }
  const handleInfiniteGetCustomers = async (page) => {

    if (loading || maxPages < page) return
    try {
      setLoading(true)
      const res = await statusAttendance(filter, page)
      const newData = res.data
      const scrollHeight = scrollRef.current.scrollHeight
      setPage(page)
      setDataList((prevCustomerList) => [...prevCustomerList, ...newData])
      setMaxPages(res.meta.pages);
      setMaxContacts(res.meta.rows);
      scrollRef.current.scrollTop = (scrollHeight * 2) - scrollHeight;
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const handleSearchContact = async (filterInput) => {

    try {
      const res = await getCustomersManager(filterInput, 1, 'Active')
      setoptionsContact(res.data)
    } catch (error) {
      console.error(error);
    }
  }

  const handleChangeChannel = (selected) => {
    setChosenChannels(selected);
  };

  const handleGetChoosedOperators = (selected) => {
    setChoosedOperators(selected)
  }
  const handleChangeDepartament = (selected) => {
    setChoosedDepartment(selected);
  };

  const handleFinishReasons = (selected) => {
    setChoosedReasons(selected);
  };


  const handleContactSelect = (selected) => {
    if (selected.length > 0) {
      setSelectedContact(selected[0]);
    }
  };

  const onChangeCalendar = (date) => {
    const momentDate = moment(date);
    const todayAt23H = moment().endOf('day');

    if (momentDate.isAfter(todayAt23H)) {
      if (calendarForFilter === 'initial') {
        setDateFrom(moment().startOf('day').format('YYYY-MM-DDT00:00:00'));
      } else if (calendarForFilter === 'final') {
        setDateTo(moment().endOf('day').format('YYYY-MM-DDT23:59:59'));
      }
    } else {
      if (calendarForFilter === 'initial') {
        const formattedStartDate = moment(date).format('YYYY-MM-DDT00:00:00');
        setDateFrom(formattedStartDate);
      } else if (calendarForFilter === 'final') {
        const formattedEndDate = moment(date).format('YYYY-MM-DDT23:59:59');
        setDateTo(formattedEndDate);
      }
    }

    setShowCalendar(false);
  };


  const handleCustomerMessages = (item) => {

    const formatDateForApi = (newDate) => {
      const currentDate = moment(newDate);
      return currentDate.format('YYYY-MM-DDTHH:mm:ss');
    }

    const itemFormatted = {
      channel: {
        id: item.channel.id,
        name: item
      },

      phone: item.customer.cellPhone,
      pushname: item.customer.name,
      externalIds: [],
      from: `${item.customer.cellPhone}@c.us`,
      attendance: null,
      tags: [],
      dateFrom: formatDateForApi(item.startedAt),
      serviceReport: true,
    }
    if (item?.status === 'finished') {
      itemFormatted.dateTo = formatDateForApi(item.finishedAt);
    }


    setActiveChatCustomer(itemFormatted)
    setChatReadOnly({ readOnly: true })
    setShowMessageHistoryModal(true)
    setMessagesForServiceReports(true)
  }

  const handleDropdown = (clickedItem) => {
    const foundCustomers = dataList.filter(customer => customer._id === clickedItem._id);
    let clickedIndex = foundCustomers.length > 1 ?
      dataList.findIndex(customer => customer._id === clickedItem._id && customer.channel?.id === clickedItem.channel?.id) :
      dataList.findIndex(customer => customer._id === clickedItem._id);
    if (clickedIndex !== -1) {
      const updatedCustomerList = [...dataList];

      let showOptionViewMessages = false
      let showOptionStartAttendance = false
      decodedToken.departments.map(({ id }) => {
        const departmentId = id
        const customerInSameDepartmentAttendance = updatedCustomerList[clickedIndex].attendance?.departmentId === departmentId
        const customerWithoutAttendance = updatedCustomerList[clickedIndex].attendance?.type === null || !updatedCustomerList[clickedIndex].attendance
        const customerInAutoAttendance = updatedCustomerList[clickedIndex].attendance?.type === 'auto'

        if (customerInSameDepartmentAttendance || customerWithoutAttendance || customerInAutoAttendance) {
          showOptionViewMessages = true
        } else {
          showOptionViewMessages = false
        }
      })

      const customerInAttendance = updatedCustomerList[clickedIndex].attendance?.type === 'inAttendance'

      if (customerInAttendance) {
        showOptionStartAttendance = false
      } else {
        showOptionStartAttendance = true
      }
      updatedCustomerList[clickedIndex] = {
        ...updatedCustomerList[clickedIndex],
        dropdownOpen: !updatedCustomerList[clickedIndex].dropdownOpen || false,
        showOptionViewMessages,
        showOptionStartAttendance
      };
      setDataList(updatedCustomerList);
    }
  };

  const findTranslate = (data) => {
    const item = situationOptions?.find(obj => obj?.value === data)
    return item ? item?.label : null

  }

  const dateFormatted = (dateString) => {
    const date = new Date(dateString);

    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${day}/${month}/${year} ${hours}:${minutes}`;
  }

  const hasFilter = () => {
    return statusService?.value
      || choosedOperators.value
      || choosedDepartment?.length
      || chosenChannels?.length
      || choosedChannel?.length
      || choosedReasons?.length
      || selectedContact;
  }

  const deleteFilters = () => {
    setSatusService([])
    setChoosedDepartment([])
    setChosenChannels([])
    setChoosedChannel([])
    setChoosedReasons([])
    setChoosedOperators([])
    setSelectedContact(null)
    typeaheadRef.current.clear()

  }

  useEffect(() => {
    handleGetCustomers()
    handleGetAttendanceTypeStatus()
    const fecthOperatorsDate = async () => {
      try {
        const res = await getUser()
        setOperatorsOptions(res.map(operator => {
          return {
            value: operator._id,
            label: operator.name
          }
        }))
      } catch (error) {
        console.error(error)
      }
    }
    fecthOperatorsDate()
    handleGetChannels()
    handleGetDepartaments()
    handleGetFinishReasons()

    setTimeout(() => {
      dispatch(sidebarAction('Relatório atendimentos'))
    }, 100);
    history.push('/relatorio-atendimentos')
  }, [])

  const itemsTableHeader = [
    { label: '', order: 0 },
    { label: 'Nome', order: 1 },
    { label: 'Número', order: 2 },
    { label: 'Departamento', order: 3 },
    { label: 'Canal', order: 4 },
    { label: 'Operador', order: 5 },
    { label: 'Situação', order: 6 },
    { label: 'Iniciado em', order: 7 },
    { label: '', order: 8 }
  ]

  const cellsTableData = [
    {
      type: "image",
      property: "customer",
      subProperty: 'image',
      order: 0,
      width: 50,
      relative: true,
      imageWidth: 40,
      imageHeight: 40,
      className: "rounded-circle mr-2",
    },
    {
      type: "longText",
      order: 1,
      width: 200,
    },
    {
      type: 'text',
      property: 'customer',
      subProperty: 'cellPhone',
      order: 2,
      width: 200,
      mask: useFormattedPhone
    },
    {
      type: 'text',
      property: 'department',
      subProperty: 'name',
      order: 3,
      width: 200,
    },
    {
      type: 'text',
      property: 'channel',
      subProperty: 'name',
      order: 4,
      width: 200,
    },
    {
      type: 'text',
      property: 'operator',
      subProperty: 'name',
      order: 5,
      width: 200,
    },
    {
      type: 'customComponent',
      order: 6,
      paddingLeft: 10,
      height: 50,
      component: (data) => (
        <div width={250}>{findTranslate(data.status)}</div>
      )
    },
    {
      type: 'customComponent',
      order: 7,
      paddingLeft: 10,
      height: 50,
      component: (data) => (
        <div width={250}>{dateFormatted(data.startedAt)}</div>
      )
    },
    {
      type: 'actions',
      property: 'actions',
      order: 8,
      width: 50,
      toggle: (data) => handleDropdown(data),
      items: [
        {
          label: 'Visualizar conversa',
          action: (data) => handleCustomerMessages(data),
          show: (data) => data.showOptionViewMessages,
        },
        {
          label: 'Detalhes',
          action: (data) => setOpenModalDetails({ open: true, info: data }),
          show: () => true,
        },
      ]
    }

  ].sort((a, b) => a.order - b.order);

  return (
    <div className="layout">
      <Chat customersRoute />
      <div className="content">
        <div className="content bg-light p-0 align-items-center text-black flex-column h-100">
          <header className='content-header w-100 d-flex align-items-center pt-4 px-5'>
            <div className='d-flex  align-items-center'>
              <p className="font-weight-bold m-3" style={{ fontSize: 18 }}>
                <span className="bg-primary p-2 rounded-lg px-3">{maxContacts || 0}</span>
              </p>
              <p className="font-weight-bold m-0 text-dark" style={{ fontSize: 18 }}>
                Relatório de atendimentos
              </p>
            </div>
          </header>
          <hr style={{ width: '100%', height: 10, borderColor: 'lightgray', marginBottom: 10 }} />
          <div className="container" style={{ overflowY: isMobile ? 'scroll' : '' }}>
            <div className="row align-items-center">
              <div className="col-12 col-lg-3 mb-1">
                <span>Data Início:</span>
                <div className='form-control input-calendar border-0 text-center font-weight-bold w-100'
                  onClick={() => {
                    if (!showCalendar) {
                      setShowCalendar('initial');
                      setCalendarForFilter('initial');
                    }
                  }}>
                  {moment(dateFrom).format('DD/MM/YYYY')}
                  {showCalendar === 'initial' ?
                    <div className="d-flex justify-content-center">
                      <Calendar
                        onChange={(date) => { onChangeCalendar(date) }}
                        value={moment(dateFrom).format()} locale='pt-br'
                      />
                    </div>
                    : null}
                </div>
              </div>
              <div className="col-12 col-lg-3 mb-1">
                <span>Data Fim:</span>
                <div className='form-control input-calendar border-0 text-center font-weight-bold w-100'
                  onClick={() => {
                    if (!showCalendar) {
                      setShowCalendar('final');
                      setCalendarForFilter('final');
                    }
                  }}
                >
                  {moment(dateTo).format('DD/MM/YYYY')}
                  {showCalendar === 'final' ?
                    <Calendar
                      onChange={(date) => { onChangeCalendar(date) }}
                      value={moment(dateTo).format()} locale='pt-br' /> : null}
                </div>
              </div>
              <div className="col-12 col-lg-3 mb-1">
                <span className="mt-2 ml-1">Situação :</span>
                <div style={{}}>
                  <Select
                    options={situationOptions}
                    name='situation_option'
                    value={statusService}
                    closeMenuOnSelect={true}
                    noOptionsMessage={(e) => 'Nenhum atendimento disponível.'}
                    placeholder="Selecione"
                    classNamePrefix={'multiselect'}
                    menuPortalTarget={document.querySelector('body')}
                    styles={{
                      menuPortal: base => ({ ...base, zIndex: 9999 }),
                      valueContainer: css => ({
                        ...css,
                        flexWrap: "nowrap",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: 'ellipsis'
                      })
                    }} isMulti={false}
                    onChange={e => setSatusService(e)}
                  />
                </div>

              </div>
              <div className="col-12 col-lg-3 mb-1">
                <span className="mt-2 ml-1">Operador :</span>
                <div>
                  <Select
                    options={operatorsOptions}
                    name='operators'
                    value={choosedOperators}
                    closeMenuOnSelect={true}
                    noOptionsMessage={(e) => 'Nenhum operador disponível.'}
                    placeholder="Selecione"
                    classNamePrefix={'multiselect'}
                    menuPortalTarget={document.querySelector('body')}
                    styles={{
                      menuPortal: base => ({ ...base, zIndex: 9999 }),
                      valueContainer: css => ({ ...css, flexWrap: "nowrap", whiteSpace: "nowrap", overflow: "hidden", textOverflow: 'ellipsis' })
                    }} isMulti={false}
                    onChange={e => handleGetChoosedOperators(e)}
                  />
                </div>
              </div>
            </div>
            <div
              className={`search-fields-container ${showSearchFields ? 'open' : ''} `}
              style={{
                overflow: showSearchFields ? 'revert' : '',
                maxHeight: isMobile && showSearchFields ? '200px' : '',

              }}
            >
              <div className='mt-3'>
                <div style={{ color: 'black' }}>
                  <div className='row align-items-center' style={{ textAlign: 'left' }}>
                    <div className='col-12 col-lg-3 mb-1'>
                      <Label for='nameCampaign' className='mt-1'>Canal:</Label>
                      <div>
                        {channels.length > 1 ?
                          <MultiSelect
                            key='channels'
                            options={channels}
                            onChange={(selectedOption) => handleChangeChannel(selectedOption)}
                            value={chosenChannels}
                            isSelectAll={true}
                            menuPlacement='bottom'
                            placeholder='Selecione'
                            selectAllName='Selecionar todas as opções'
                            firstNameSelect='Todos as'
                            lastNameSelec='opções selecionadas'
                            isMulti={false}
                            messageForSingular='selecionado'
                            messageForPlural='selecionados'
                            styles={{
                              menuPortal: base => ({ ...base, zIndex: 9999 })
                            }}
                          />
                          : <Select
                            options={channels}
                            name='channels'
                            value={choosedChannel}
                            closeMenuOnSelect={false}
                            noOptionsMessage={(e) => 'Nenhum canal disponível.'}
                            placeholder="Selecione"
                            classNamePrefix={'multiselect'}
                            menuPortalTarget={document.querySelector('body')}
                            styles={{
                              menuPortal: base => ({ ...base, zIndex: 9999 }),
                              valueContainer: css => ({ ...css, flexWrap: "nowrap", whiteSpace: "nowrap", overflow: "hidden", textOverflow: 'ellipsis' })
                            }} isMulti={channels.length > 1}
                            onChange={e => setChoosedChannel(e)}
                          />
                        }
                      </div>
                    </div>
                    <div className='col-12 col-lg-3 mb-1'>
                      <Label for='nameDepartamen' className='mt-1'>Departamento:</Label>
                      {department.length > 1 ?
                        <MultiSelect
                          key='departament'
                          options={department}
                          onChange={(selectedOption) => handleChangeDepartament(selectedOption)}
                          value={choosedDepartment}
                          isSelectAll={true}
                          menuPlacement='bottom'
                          placeholder='Selecione'
                          selectAllName='Selecionar todas as opções'
                          firstNameSelect='Todos as'
                          lastNameSelec='opções selecionadas'
                          isMulti={false}
                          messageForSingular='selecionado'
                          messageForPlural='selecionados'
                          styles={{
                            menuPortal: (base) => ({ ...base, zIndex: 9999 })
                          }}
                        />
                        : <Select
                          options={department}
                          name='departament'
                          value={choosedDepartment}
                          closeMenuOnSelect={false}
                          noOptionsMessage={(e) => 'Nenhum canal disponível.'}
                          placeholder="Selecione"
                          classNamePrefix={'multiselect'}
                          menuPortalTarget={document.querySelector('body')}
                          styles={{
                            menuPortal: base => ({ ...base, zIndex: 9999 }),
                            valueContainer: css => ({ ...css, flexWrap: "nowrap", whiteSpace: "nowrap", overflow: "hidden", textOverflow: 'ellipsis' })
                          }} isMulti={channels.length > 1}
                          onChange={e => setChoosedDepartment(e)}
                        />}
                    </div>
                    <div className='col-12 col-lg-3 mb-1'>
                      <Label for='nameReason' className='mt-1'>Motivo de finalização:</Label>
                      {finishReasons.length > 1 ?
                        <MultiSelect
                          key='reason'
                          options={finishReasons}
                          onChange={(selectedOption) => {
                            handleFinishReasons(selectedOption);
                          }}
                          value={choosedReasons}
                          isSelectAll={true}
                          menuPlacement='bottom'
                          placeholder='Selecione'
                          selectAllName='Selecionar todas as opções'
                          firstNameSelect='Todos as'
                          lastNameSelec='opções selecionadas'
                          isMulti={false}
                          messageForSingular='selecionado'
                          messageForPlural='selecionados'
                          styles={{
                            menuPortal: (base) => ({
                              ...base,
                              zIndex: 9999
                            })
                          }}

                        />
                        : <Select
                          options={channels}
                          name='channels'
                          value={choosedChannel}
                          closeMenuOnSelect={false}
                          noOptionsMessage={(e) => 'Nenhum canal disponível.'}
                          placeholder="Selecione"
                          classNamePrefix={'multiselect'}
                          menuPortalTarget={document.querySelector('body')}
                          styles={{
                            menuPortal: base => ({ ...base, zIndex: 9999 }),
                            valueContainer: css => ({ ...css, flexWrap: "nowrap", whiteSpace: "nowrap", overflow: "hidden", textOverflow: 'ellipsis' })
                          }} isMulti={channels.length > 1}
                          onChange={e => setChoosedChannel(e)}
                        />}
                    </div>
                    <div className='col-12 col-lg-3 mb-1'>
                      <Label for='contact' className='mt-1'>Contato:</Label>
                      <AsyncTypeahead
                        filterBy={filterBy}
                        searchText="Carregando contatos..."
                        emptyLabel="Contato não encontrado"
                        promptText="Procurando resultados"
                        id="contactInput"
                        isLoading={loading}
                        onChange={handleContactSelect}
                        labelKey={(option) => option.name || ''}
                        minLength={1}
                        onSearch={handleSearchContact}
                        options={optionsContact}
                        ref={typeaheadRef}
                        placeholder="Contato"
                        menuStyle={{
                          position: 'absolute',
                          zIndex: 1050,
                          maxHeight: '300px',
                          overflowY: 'auto',
                        }}
                        renderMenuItemChildren={(option) => (
                          <div style={{ padding: 0 }}>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                maxWidth: '95%',
                                fontSize: '12px',
                              }}
                            >
                              <img
                                alt={option?.pushname}
                                src={option?.image|| DefaultImage}
                                style={{
                                  height: '34px',
                                  width: '34px',
                                  marginRight: '10px',
                                  borderRadius: '50%',
                                }}
                              />
                              <div style={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                maxWidth: '100%'
                              }}>
                                <strong>{option.name}</strong>
                                <br />
                                <span>{option.phoneFormatted}</span>
                                <br />
                                <span>{option.channel.name}</span>
                              </div>
                            </div>
                          </div>
                        )}
                      />
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <div className="row justify-content-center"
              style={{
                overflowY: isMobile && showSearchFields ? 'auto' : '',
                marginTop: isMobile && showSearchFields ? '90px' : ''
              }}
            >
              <div className="col-auto mb-1 d-flex justify-content-center mt-3" style={{ marginRight: isMobile ? '' : '50%', marginLeft: isMobile ? '' : '50%', }}>
                <button className="btn btn-primary mr-2"
                  style={{ marginTop: '2px' }}
                  onClick={() => handleSearch()}
                >
                  {'Pesquisar'}
                </button>
                <button className="btn btn-secondary mr-2"
                  style={{ marginTop: '2px' }}
                  id="filtersButton"
                  onClick={toggleSearchFields}
                >
                  {showSearchFields ? 'Ocultar filtros' : '+ Filtros'}
                </button>
                {hasFilter() && (
                  <button className="btn btn-secondary mr-2"
                    style={{ marginTop: '2px' }}
                    onClick={() => deleteFilters()}
                  >
                    Limpar filtros
                  </button>
                )}
              </div>

            </div>
          </div>
          <hr style={{ width: '100%', height: 10, borderColor: 'lightgray', marginTop: showSearchFields ? 30 : 10 }} />
          <div className="sidebar-body w-100 pb-4 px-5 pt-0 position-relative" style={{ height: '80%', overflowY: 'hidden' }} id="sidebar-customers">
            {loading &&
              <div className="overlay-loading-customers">
                <i className="fa fa-spinner" style={{ animation: 'spin 3s linear infinite', fontSize: 42 }}></i>
              </div>
            }

            {Array.isArray(dataList) ?
              <PerfectScrollbar
                onScrollY={() => {
                  const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
                  if (scrollTop + clientHeight >= scrollHeight - 20) {
                    handleInfiniteGetCustomers(page + 1);
                  }
                }}
                containerRef={(ref) => (scrollRef.current = ref)}
                className="hide-rail-x"
              >
                <>
                  {
                    isMobile ? (
                      <Table>
                        <CardsLayoutServiceReports
                          data={dataList}
                          loading={loading}
                          dateFormatted={dateFormatted}
                          findTranslate={findTranslate}
                          handleDropdown={handleDropdown}
                          handleCustomerMessages={handleCustomerMessages}
                          openModalDetails={openModalDetails}
                          setOpenModalDetails={setOpenModalDetails}
                        />
                      </Table>
                    ) : (
                      <Table>
                        <TableHeader itemsTableHeader={itemsTableHeader} theadClassName={'thead-customers'} />
                        <TableBody data={dataList} cellsTableData={cellsTableData} bodyClassName={'tbody-customers'} loading={loading} />
                      </Table>
                    )
                  }
                </>
              </PerfectScrollbar>
              : null}
          </div>
          <DetailsModal
            openModalDetails={openModalDetails}
            setOpenModalDetails={setOpenModalDetails}
            findTranslate={findTranslate}
          />
        </div>
      </div>
    </div>

  )
}

export default LayoutServiceReports