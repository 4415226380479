import React from 'react'
import { ChildrenItemCell } from './ChildrenItemCell'

export const LongTextCell = ({ cell }) => {
  const { index, width, propertyExist, item, property, childrenItem, action } = cell

  const getContent = () => {
    if (item?.customer?.name) return item.customer.name
    return propertyExist ? item[property] : '-'
  }

  const longTextContent = getContent()

  return (
    <td height={70} key={index} width={width} onClick={() => action ? action(item) : null}>
      <p className='m-0 overflow-hidden text-nowrap' style={{ width: width - 50, textOverflow: 'ellipsis' }} title={longTextContent}>
        {longTextContent}
      </p>
      {childrenItem && ChildrenItemCell(item, childrenItem)}
    </td>
  )
}
