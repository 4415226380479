import React, { useEffect, useRef } from 'react';

const CustomAreaText = ({
    value,
    onChange,
    placeholder,
    onKeyDown,
    inputRef,
    disabled,
    style,
    autoComplete,
    acceptedFiles,
    setAttachment,
    setOpenModalAttachment,
    id
}) => {
    const textareaRef = useRef(null)
    const adjustTextareaHeight = (textarea) => {
        if (!textarea) return;
        textarea.style.height = '40px';
        textarea.style.height = `${textarea.scrollHeight}px`;
    };

    useEffect(() => {
        const textarea = textareaRef.current;
        const handlePaste = (evt) => {
            const clipboardItems = evt.clipboardData?.items;
            if (!clipboardItems) return;

            const items = Array.from(clipboardItems).filter((item) =>
                item.kind === "file" && acceptedFiles.includes(item.type)
            );

            if (items.length > 0) {
                const file = items[0].getAsFile();
                setAttachment(file);
                setOpenModalAttachment(true);
            }
        };

        if (textarea) {
            textarea.addEventListener("paste", handlePaste);
        }

        if (inputRef) {
            inputRef.current = textarea;
        }

        return () => {
            if (textarea) {
                textarea.removeEventListener("paste", handlePaste);
            }
        };
    }, [acceptedFiles, setAttachment, setOpenModalAttachment, inputRef]);

    useEffect(() => {
        adjustTextareaHeight(textareaRef.current);
    }, [value]);

    return (
        <textarea
            id={id}
            ref={textareaRef}
            className="form-control"
            placeholder={placeholder}
            value={value}
            disabled={disabled}
            onChange={onChange}
            onKeyDown={onKeyDown}
            style={{ ...style, resize: 'none', height: 40, maxHeight: 200 }}
            autoComplete={autoComplete}
        />
    );
};

export default CustomAreaText;